import React from 'react';
import Layout, { Content } from '../../components/Layout';
import Form from '../../components/forms/FullApplication';

const FullApplication =  () => {
  return (
    <Layout title={'Full Application'}>
      <Content style={{ marginLeft: '0', margin: '0 auto' }}>
        <Form />
      </Content>
    </Layout>
  );
};

export default FullApplication;
import React, { useState, useEffect } from 'react';
import Form from './Form';
import { FIELD_TYPES, DATA_MODELS } from './Form/Field';
import { useMyBunac } from '../../hooks/useMyBunac';

const FORM_ID = 'full_application';

const formConfig = [
  {
    type: FIELD_TYPES.lineOfText,
    label: (
      <p>
        Please enter your information here EXACTLY as shown in your passport as
        this information will be used for all bookings/reservations and visa
        applications.
      </p>
    ),
  },
  {
    label: 'Title',
    name: 'Title_Person',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'First Name',
    name: 'First Name',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: '(Middle Names)',
    name: 'Middle Names',
    model: DATA_MODELS.person,
  },
  {
    label: 'Last Name',
    name: 'Name',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Phone number',
    name: 'Phone',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Email Address',
    name: 'Email',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Date of Birth',
    name: 'Date of Birth',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Nationality (from your passport)',
    name: 'Nationality (from your passport)',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  // ADDRESS
  {
    label: 'Address Line 1',
    name: 'Address Line 1',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Address Line 2',
    name: 'Address Line 2',
    model: DATA_MODELS.person,
  },
  {
    label: 'Town',
    name: 'Town',
    model: DATA_MODELS.person,
  },
  {
    label: 'State/County',
    name: 'State/County',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Zip/Postal code',
    name: 'ZIP / Postal Code',
    model: DATA_MODELS.person,
  },
  {
    label: 'Country',
    name: 'Country',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Emergency Contact Name',
    name: 'Emergency Contact Name',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Emergency Contact phone number',
    name: 'Emergency Contact phone No.',
    isRequired: true,
    model: DATA_MODELS.person,
  },
];

export default ({ title, application, activities, onSubmit }) => {
  const { profile } = useMyBunac();
  const [initValues, setInitValues] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    if (!profile) {
      return setInitValues({});
    }

    setInitValues({
      ...profile,
      phone: profile.phone ? profile.phone[0].value : undefined,
      email: profile.email ? profile.email[0].value : undefined,
    });
  }, [profile]);

  useEffect(() => {
    if (application) {
      setBreadcrumbs([
        {
          label: 'Applications',
          to: '/mybunac/applications',
        },
        {
          label: application.programmeDetails.title,
        },
        {
          label: 'Full Application',
        },
      ]);
    }
  }, [application]);

  return (
    <Form
      headline="Complete Your Application"
      formId={FORM_ID}
      config={formConfig}
      initValues={initValues}
      activities={activities}
      dealId={application ? application.id : undefined}
      breadcrumbs={breadcrumbs}
      onSubmit={onSubmit}
    />
    )
}
